<template>
  <div class="reports">
     <v-tabs
      background-color="primary"
      dark
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.name"
        :to="{ name: tab.name }"
      >
        {{ tab.label }}
      </v-tab>
    </v-tabs>
    <div class="reports__screen">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    tabs () {
      const { items } = this.$store.state.menu
      const { children = [] } = items.find(({ name }) => name === 'AllReports')
      return children
        .filter(({ meta }) => meta?.inTabs !== false)
        .map(({ name, meta }) => {
        return {
          name,
          label: meta.label
        }
      })
    }
  }
}
</script>

<style scoped>
.reports {
  display: flex;
  flex-direction: column;
}
.reports__screen {
  flex: 1 1 100%;
}
</style>
